import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import LangContext from "../context/LangContext";
import { AllDirectusPhiloContent, Pages } from "../models";

interface Props {
  site: {
    siteMetadata: {
      pages: Pages;
    };
  };
}

const CarteSaison: React.FC<PageProps<Props & AllDirectusPhiloContent>> = ({ data }) => {
  const ctx = useContext(LangContext);
  const title = data.site.siteMetadata.pages.seasonMenu[ctx.lang];
  const items = data.allDirectusPhiloContent.nodes
    .sort((a, b) => a.order - b.order)
    .map(i => i.translations.find(t => t.language === "fr"))
    .filter(i => i.illustration && i.illustration.data && i.illustration.data.full_url)
    .map(i => i.illustration.data.full_url);

  return (
    <Layout>
      <SEO title={title} />
      <div className="seasonMenu page-container">
        <h1>{title}</h1>
       
        <Container fluid={true}>
          {items.map((item, index) => (
            <Row key={index}>
              <Col style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto" }} lg={5}>
                <img src={item} alt={`season-menu-${index}`} className="seasonItem" />
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allDirectusPhiloContent(filter: { page: { eq: "season-menu" } }) {
      nodes {
        name
        page
        order
        translations {
          language
          title
          subtitle
          content
          illustration {
            data {
              full_url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        pages {
          seasonMenu {
            fr
            de
            it
            en
          }
        }
      }
    }
  }
`;

export default CarteSaison;
